@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
//@import url('https://fonts.googleapis.com/css?family=Hind+Vadodara:300,600');

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
//$family-sans-serif: 'Hind Vadodara', sans-serif;
$grey-dark: #545454;
$grey-light: #dedede;
$primary: #03a9f4;
$link: #006a9c;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/form/_all';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
