.authLogo {
  max-height: 80px;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  animation-name: hideLoader;
  animation-duration: .5s;
}

.loader-wrapper .loader {
  height: 80px;
  width: 80px;
}

.loader-wrapper.is-active {
  opacity: 1;
  z-index: 1;
}

.loader-wrapper-parent {
  position: relative;
  height:80px;
}


@keyframes hideLoader {
  0% {opacity: 1; z-index: 1;}
  90% {opacity: 0; z-index: 1;}
  100% {opacity: 0; z-index: -1;}
}

.input-range__track--active,
.input-range__slider {
    background: #03a9f4 !important;
    border-color: #03a9f4 !important;
} 

.is-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

/* CALENDAR */

.hight.availability .fc-list-event-dot{
  border-color: #23d160;
}

.medium.availability .fc-list-event-dot{
  border-color: #ffdd58;
}

.low.availability .fc-list-event-dot{
  border-color: #ff3860;
}

@media screen and (max-width: 1024px){
  .bookingCalendarComponent .fc-_listWeek-button, .bookingCalendarComponent .fc-_listDay-button, .bookingCalendarComponent .fc-_grid-button{
    display: none!important;
  }

  .bookingCalendarComponent h2.fc-toolbar-title{
    font-size: 1em;
    font-weight: bold;
  }
}

/* footer */

.footLogo img{
  width: 48px;
}


/* terms */
.legalParagraph{
}

/* .legalParagraph::-webkit-scrollbar {
  width: 10px;
}

.legalParagraph::-webkit-scrollbar-track {
  background: #FFFFFF66;
}

.legalParagraph::-webkit-scrollbar-thumb {
  background-color: #03a9f4;
  border-radius: 20px; 
  border: 1px solid #FFF; 
} */

.terms{
  padding: 10px;
  background-color: #FAFAFA;
}

.navbarLanguage{
  /* display: none; */
  position: fixed;
  top:5px;
  right:5px;
  background-color: #FAFAFA;
  z-index: 10;
}


/*NEW 5/2021*/
.tableMoreInfo{
  font-size: .65em;
}

.csvImporterInfo{
  padding: 20px 0;
  display: inline-block;
}

.datepicker-readonly{
  border: none!important;
}

.sep{
  height: 1px;
  background-color: #EEE;
  width: 100%;
  margin:2px 0;
}

.has-markdown ul{
  margin-left: 10px;
}

.dropdown .dropdown-item:hover{
  background-color: #F7F7F7;
}

.dataTable{
  font-size: .8em;
}

.tessera_payed .rs-table-cell {
  background-color: #f0bf6b!important
}

.consenso_No .rs-table-cell{
  background-color: #f0916b!important;
}